<template>
  <div class="human">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>劳务外包</h3>
          <p>一站式劳务用工解决方案</p>
          <p>人员外包 * 入/离职 *社保*工资*劳务合同管理*纠纷处理等</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank" @click="uet_report_conversion()">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/haman_banner.jpg" />
    </div>

    <div class="pay_one">
      <div class="all_box">
        <div class="pay_one_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>
        <div class="pay_one_box">
          <div class="pay_one_body">
            <!-- <img src="@/assets/humanImg.png" /> -->
            <div class="pay_one_top_list">
              <div class="pay_one_top_item">
                <div class="pay_one_item_img">
                  <img src="@/assets/pay_one01.png" />
                </div>
                <div class="pay_one_top_text">
                  <h4>员工招聘服务</h4>
                  <p>解决招聘难题，根据业务需求计划增减员</p>
                </div>
              </div>
              <div class="pay_one_top_item">
                <div class="pay_one_item_img">
                  <img src="@/assets/pay_one02.png" />
                </div>
                <div class="pay_one_top_text">
                  <h4>员工社保服务</h4>
                  <p>社保账户开立、托管、增减员、基数调整、异地员工缴纳，用退工备案</p>
                </div>
              </div>
              <div class="pay_one_top_item">
                <div class="pay_one_item_img">
                  <img src="@/assets/pay_one03.png" />
                </div>
                <div class="pay_one_top_text">
                  <h4>薪酬个税服务</h4>
                  <p>本地/异地员工智能化核薪算薪、工资条在线发放、报税，优化薪酬结构</p>
                </div>
              </div>
              <div class="pay_one_top_item">
                <div class="pay_one_item_img">
                  <img src="@/assets/pay_one04.png" />
                </div>
                <div class="pay_one_top_text">
                  <h4>用工风险管理</h4>
                  <p>劳动合同管理、劳资纠纷预防、调节、争议处理、工伤处理</p>
                </div>
              </div>
            </div>

            <div class="pay_one_bottom">
              <div class="pay_one_bottom_title">"企业是否面临以下问题"</div>
              <div class="pay_one_bottom_list">
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom01.png"
                  />？员工流动率高，招聘难度大、成本高
                </div>
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom02.png"
                  />？薪资支付流程不够精确
                </div>
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom03.png"
                  />？人事专员能力欠佳，突发事务多
                </div>
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom04.png"
                  />？企业跨地区分支机构员工事务难以统筹管理
                </div>
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom05.png"
                  />？劳资双方关系紧张，劳动纠纷繁杂
                </div>
                <div class="pay_one_bottom_item">
                  <img
                    src="@/assets/pay_one_bottom06.png"
                  />？存在短时临时用工需求
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="henfu_po">
      <henfu></henfu>
    </div>

    <div class="human_two">
      <div class="all_box">
        <div class="human_two_body">
          <div class="human_two_title">
            <textTitle
              :cnTitle="txt.twoCnTitle"
              :enTitle="txt.twoEnTitle"
            ></textTitle>
          </div>
          <div class="human_two_list">
            <div class="human_two_item">
              <div class="human_two_top">{{ $config.bmYears }}年丰富服务经验</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>海量合作案例，更成熟专业</p>
                </div>
                <img src="@/assets/human_icon01.png" />
              </div>
            </div>
            <div class="human_two_item">
              <div class="human_two_top">全国400+网点服务能力</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>直营落地服务，便捷高效</p>
                </div>
                <img src="@/assets/human_icon02.png" />
              </div>
            </div>
            <div class="human_two_item">
              <div class="human_two_top">用工风险转移</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>人力成本转为外包成本，有效降低成本</p>
                </div>
                <img src="@/assets/human_icon03.png" />
              </div>
            </div>
            <div class="human_two_item">
              <div class="human_two_top">在线服务SaaS平台</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>在线实时申报、电子代办、保密可查</p>
                </div>
                <img src="@/assets/human_icon04.png" />
              </div>
            </div>
            <div class="human_two_item">
              <div class="human_two_top">专业合规资质</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>正规人力资源许可，可靠放心</p>
                </div>
                <img src="@/assets/human_icon05.png" />
              </div>
            </div>
            <div class="human_two_item">
              <div class="human_two_top">专属客服顾问</div>
              <div class="human_two_bottom">
                <div class="human_two_bottom_p">
                  <p>一对一客服跟踪管理服务</p>
                </div>
                <img src="@/assets/human_icon06.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <serveFlow :dataList="serveList"></serveFlow>

    <check :type="2" @gotoContact="gotoContact($event)"></check>
    <foots @navChange = "navChange"></foots>
  </div>
</template>
<script>
//图片
import hu01 from "@/assets/hu01.png";
import hu02 from "@/assets/hu02.png";
import hu03 from "@/assets/hu03.png";
import hu04 from "@/assets/hu04.png";
import hu05 from "@/assets/hu05.png";
import hu06 from "@/assets/hu06.png";

import textTitle from "@/components/textTitle.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import check from "@/components/check.vue";
import henfu from "@/components/henfu.vue";
import serveFlow from "@/components/serveFlow.vue";
export default {
  name: "Human",
  components: {
    titles,
    foots,
    check,
    textTitle,
    henfu,
    serveFlow,
  },
  data() {
    return {
      txt: {
        oneCnTitle: "企业员工到岗离岗全程支持外包用工",
        oneEnTitle: "Outsourced labor",
        twoCnTitle: "“选邦芒人力，有效为您降低用工成本30%”",
        twoEnTitle: "Select Bangmang",
      },
      serveList: [
        {
          title: "在线咨询",
          img: hu01,
        },
        {
          title: "制定专属方案",
          img: hu02,
        },
        {
          title: "签订服务协议",
          img: hu03,
        },
        {
          title: "实施服务",
          img: hu04,
        },
        {
          title: "支付结算",
          img: hu05,
        },
        {
          title: "企业用工过程支持",
          img: hu06,
        },
      ],
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      window.scrollTo(0, 0);
    },
    uet_report_conversion() {
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact', {});
      console.log('被点击')
    }
  },
};
</script>
<style lang="scss" scoped>
.pay_one_bottom {
  background: #f3efea;
  padding: 22px 47px 10px;
  box-sizing: border-box;
  border-radius: 40px;
  .pay_one_bottom_title {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .pay_one_bottom_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .pay_one_bottom_item{
      text-align: left;
      width: 33%;
      margin-bottom: 30px;
      line-height: 37px;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      img{
        margin-bottom: -13px;
        margin-right: 18px;
      }
    }
  }
}
.pay_one_top_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 45px;
}
.pay_one_top_item {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 55px;
  .pay_one_top_text {
    text-align: left;
    margin-left: 18px;
    h4 {
      margin: 0;
      font-size: 18px;
      color: #b59167;
      margin-bottom: 15px;
    }
    p {
      margin: 0;
      font-size: 14px;
      color: #333333;
    }
  }
}
.pay_one_body {
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 72px 15px  55px 15px;
}
.pay_one_title {
  padding: 100px 0;
}
.pay_one {
  background: #f2f2f2;
}
.pay_one_box {
  position: relative;
  height: 500px;
}
.pay_one_body {
  position: absolute;
}
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 90px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #fff;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #fff;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}
.human_two_title {
  padding: 100px 0;
}
.human_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .human_two_item {
    width: 16%;
    border-radius: 30px;
    overflow: hidden;

    .human_two_top {
      font-size: 16px;
      color: #ffffff;
      background: #b49168;
      padding: 20px 0;
    }
    .human_two_bottom {
      font-size: 14px;
      color: #333333;
      background: #f5f5f5;
      padding-bottom: 20px;
      .human_two_bottom_p {
        height: 85px;
        p {
          padding-top: 30px;
          padding-bottom: 35px;
          margin: 0;
        }
      }
    }
  }
}
</style>